.app-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-size: 2vw;
  font-family: 'Material Symbols Rounded', sans-serif;
}
